import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, Inject, OnInit, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import {
  AADUser,
  EntityObject,
  Filter,
  GroupUser,
  Ishtar365CommunicationService,
  MicrosoftAuthenticationService,
} from 'processdelight-angular-components';
import {
  Observable,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  map,
  of,
  startWith,
} from 'rxjs';
import { TimeSortFacade } from 'src/app/core/store/time-sort/time-sort.facade';
import { TimeSort } from 'src/app/core/domain/models/time-sort';
import { TimeRegistrationFacade } from 'src/app/core/store/time-registration/time-registration.facade';
import { TimeRegistration } from 'src/app/core/domain/models/time-registration.model';
import {
  license$,
  projects$,
  tasks$,
  timeSorts$,
  translations$,
} from 'src/app/core/data/data.observables';
import { Task } from 'src/app/core/domain/models/task';
import { Project } from 'src/app/core/domain/models/project';
import { DummyProject } from 'src/app/core/domain/models/dummy-project.model';
import { TimeInputDirective } from 'src/app/configuration/shared/directives/time-input.directive';
import { NoSpaceDirective } from 'src/app/configuration/shared/directives/no-space.directive';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { DummyProjectFacade } from 'src/app/core/store/dummy-project/dummy-project.facade';
import { Skill } from 'src/app/core/domain/models/skill.model';
import { DateTime } from 'luxon';
import { TaskType } from '../../core/domain/models/task-type.model';
import { ApiService } from '../../core/services/api.service';
import { IshtarTask } from '../../core/domain/models/ishtar-task.model';

export const SNACKBAR_DURATION_MS = 3000;

@Component({
  standalone: true,
  selector: 'app-registration-details',
  templateUrl: './registration-details.component.html',
  styleUrls: ['./registration-details.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule,
    TimeInputDirective,
    NoSpaceDirective,
  ],
})
export class RegistrationDetailsComponent implements OnInit, OnDestroy {
  translations = translations$.value;
  license = license$.value;

  defaultTimeSlot = 1;
  registrationForm!: FormGroup;
  showTasksDropdown = true;
  showSkillsDropdown = false;
  timeRegistrations: TimeRegistration[] = [];

  timeSorts = timeSorts$.value;
  skills: Skill[] = [];
  filteredTimeSorts: Observable<TimeSort[] | undefined> | undefined;
  filteredTypes: Observable<TaskType[] | undefined> | undefined;
  filteredTemplates: Observable<EntityObject<IshtarTask>[] | undefined> | undefined;
  filteredSkills: Observable<Skill[] | undefined> | undefined;
  filteredSkillsByTimeSort: Skill[] = [];

  projects = projects$.value;
  dummyProjects: DummyProject[] = [];
  types: TaskType[] = [];
  selectedTypeId = '';

  filteredProjects: Observable<Project[] | undefined> | undefined;
  filteredDummyProjects: Observable<DummyProject[] | undefined> | undefined;
  projectFilters: Filter[] = [];

  tasks = tasks$.value;
  filteredTasks: Observable<Task[] | undefined> | undefined;
  filteredTasksByProject: Task[] = [];
  destroy$ = new Subject<void>();

  timeSortControl = new FormControl('');
  typeControl = new FormControl('');
  templateControl = new FormControl('');
  skillControl = new FormControl('');
  projectControl = new FormControl('');
  dummyProjectControl = new FormControl('');
  taskControl = new FormControl('');

  selectedTimeRegistration!: TimeRegistration;
  selectedType!: TaskType;
  editMode = false;
  copyMode = false;
  isLoading = false;
  createNewTask = false;
  newTimeRegistration = false;
  hasClear = false;
  selectedSubType = false;
  selectedUserIds: string[] = [];
  selectedUsers: GroupUser[] | undefined = [];
  formTemplates: EntityObject<IshtarTask>[] = [];
  selectedEntityObjects: EntityObject<IshtarTask>[] = [];
  selectedEntityObject?: EntityObject<IshtarTask>;

  startDate!: DateTime;
  endDate!: DateTime;

  currentUserId = '';

  private valueChangesSubscription = new Subscription();
  get userFormControl() {
    return this.registrationForm.get('users') as FormControl;
  }

  get userControlVal() {
    return this.userFormControl.value as string[];
  }

  get timeSortFormControl() {
    return this.registrationForm.get('timeSort') as FormControl;
  }

  get timeSortControlVal() {
    return this.timeSortFormControl.value as string;
  }

  get templateFormControl() {
    return this.registrationForm.get('template') as FormControl;
  }

  get templateControlVal() {
    return this.templateFormControl.value as string;
  }


  get typeFormControl() {
    return this.registrationForm.get('type') as FormControl;
  }

  get typeControlVal() {
    return this.typeFormControl.value as string;
  }

  get skillFormControl() {
    return this.registrationForm.get('skill') as FormControl;
  }

  get skillControlVal() {
    return this.skillFormControl.value as string;
  }

  get projectFormControl() {
    return this.registrationForm.get('project') as FormControl;
  }

  get projectControlVal() {
    return this.projectFormControl.value as string;
  }

  get dummyProjectFormControl() {
    return this.registrationForm.get('dummyProject') as FormControl;
  }

  get dummyProjectControlVal() {
    return this.dummyProjectFormControl.value as string;
  }

  get taskFormControl() {
    return this.registrationForm.get('task') as FormControl;
  }

  get taskControlVal() {
    return this.taskFormControl.value as string;
  }

  @ViewChild('userDropdown')
  userDropdown?: ElementRef<HTMLInputElement>;
  @ViewChild('timeSortDropdown')
  timeSortDropdown?: ElementRef<HTMLInputElement>;
  @ViewChild('typeDropdown')
  typeDropdown?: ElementRef<HTMLInputElement>;
  @ViewChild('templateDropdown')
  templateDropdown?: ElementRef<HTMLInputElement>;
  @ViewChild('projectDropdown')
  projectDropdown?: ElementRef<HTMLInputElement>;
  @ViewChild('dummyProjectDropdown')
  dummyProjectDropdown?: ElementRef<HTMLInputElement>;
  @ViewChild('taskDropdown')
  taskDropdown?: ElementRef<HTMLInputElement>;
  @ViewChild('skillDropdown')
  skillDropdown?: ElementRef<HTMLInputElement>;

  constructor(
    private detailsDialogRef: MatDialogRef<RegistrationDetailsComponent>,
    private timeSortsFacade: TimeSortFacade,
    private timeRegistrationFacade: TimeRegistrationFacade,
    private dummyProjectFacade: DummyProjectFacade,
    private _snackBar: MatSnackBar,
    private ishtarCommunicationsService: Ishtar365CommunicationService,
    private dateAdapter: DateAdapter<any>,
    private apiService: ApiService,
    private msalService: MicrosoftAuthenticationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selectedUserIds: string[];
      isManager: boolean,
      users: AADUser[];
      copyMode: boolean;
      timeRegistrationItem: TimeRegistration;
      startDate: DateTime; //originalStartDate,
      endDate: DateTime; //originalEndDate,
      timeRegistrations: TimeRegistration[];
    }
  ) {
    this.startDate = DateTime.fromMillis(data.startDate.valueOf(), {
      zone: 'utc',
    }); // new Date(data.startDate)
    this.endDate = DateTime.fromMillis(data.endDate.valueOf(), { zone: 'utc' }); // new Date(data.endDate)
    this.timeRegistrations = data.timeRegistrations;

    if (data?.timeRegistrationItem) {
      this.selectedTimeRegistration = data.timeRegistrationItem;
      this.editMode = data?.copyMode ? false : true;
      this.copyMode = data?.copyMode;
    } else {
      this.endDate = this.endDate.set({
        hour: this.endDate.hour + 1,
      }); //this.endDate?.setHours(this.endDate.getHours() + 1);
    }

    this.currentUserId = this.msalService.userId;
  }

  ngOnInit() {
    this.loadDummyProjects();
    this.loadTimeSorts();
    this.loadProjects();
    this.loadTasks();
    this.loadTypes();

    this.apiService.getFormTemplates().subscribe((data) => {
      this.formTemplates = data;
    });

    combineLatest([
      this.timeSortsFacade.timeSorts$,
      this.timeSortsFacade.allSelectedCommonUsersSkills$(this.data.selectedUserIds),
    ]).subscribe(([ts, us]) => {
      this.skills = us
        .flatMap((us) => us.skill)
        .map(
          (s) =>
            new Skill({
              ...s,
            })
        );
      this.timeSorts = ts?.filter((t) => {
        if ((t.skills?.length ?? 0) === 0) {
          return true;
        } else if (
          t.skills?.some((s) => this.skills.some((sk) => sk?.id === s.id))
        ) {
          return t.skills?.some((s) =>
            this.skills.some((sk) => sk?.id === s.id)
          );
        }
        return false;
      });
    });

    this.initForm();
    this.handleStartDateTimeChanged();


    if (this.selectedTimeRegistration?.timeSortId) {
      this.onTimeSortChange(this.selectedTimeRegistration.timeSortId);
      this.skillFormControl.setValue(
        this.selectedTimeRegistration?.skillId ?? null
      );
    }

  }

  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  getTimeSortValue(timeSortId: string) {
    const timeSortValue = this.timeSorts?.find(
      (t) => timeSortId === t.id
    )?.sort;
    return this.getTranslation(timeSortValue);
  }

  getTypeValue(typeId: string) {
    const typeValue = this.types?.find(
      (t) => typeId === t.id
    )?.type;
    return this.getTranslation(typeValue);
  }

  getTemplateValue(formId: string) {
    const form = this.formTemplates.find(f => f.formId == formId)?.form;
    return this.getTranslation(form ? form.name : "");
  }

  getSkillValue(skillId: string) {
    const skillValue = this.skills?.find((s) => skillId === s.id)?.title;
    return (this.getTranslation(skillValue)?.length ?? 0) > 0
      ? this.getTranslation(skillValue)
      : skillValue;
  }

  getProjectValue(projectId: string) {
    const filteredTasks =
      this.tasks?.slice()?.filter((task) => task.projectId === projectId) ??
      [];
    this.showTasksDropdown = (filteredTasks?.length ?? 0) > 0;
    const project = this.projects?.find((p) => projectId === p.id);
    return project?.projectId + ' ' + project?.projectName;
  }

  getDummyProjectValue(dummyProjectId: string) {
    return this.dummyProjects?.find((p) => dummyProjectId === p.id)?.title;
  }

  getTaskValue(taskId: string) {
    return this.tasks?.find((t) => taskId === t.id)?.title;
  }

  getTranslation(label: string | undefined): string | undefined {
    if (label) {
      return translations$.value[label]?.length > 0
        ? translations$.value[label]
        : label;
    }
    return undefined;
  }


  onTypeChange(event: any) {
    this.selectedTypeId = this.typeControlVal;
    const type = this.types.find(t => t.id == this.selectedTypeId);
    if (type) {
      this.selectedSubType = true;
      this.selectedEntityObjects = this.formTemplates.filter(f => f.entity?.typeId == this.selectedTypeId);
      const templateControlValueChanges$ =
        this.templateControl?.valueChanges.pipe(startWith('')) || of('');
      const templateFormControlValueChanges$ =
        this.templateControl?.valueChanges.pipe(startWith([])) || of([]);

      this.filteredTemplates = combineLatest([
        templateControlValueChanges$,
        templateFormControlValueChanges$,
      ]).pipe(map(([value]) => this._templateFilter(value || '')));

    }
    this._typeFilter(this.typeControl.value ?? '');
  }

  onTemplateChange(event: any) {
    const selectedFormId = this.templateControlVal;
    this.selectedEntityObject = this.selectedEntityObjects.find(o => o.formId == selectedFormId);
  }

  onTimeSortChange(event: any) {
    this.clearSelectedSkill();
    if (event) {
      const selectedTimeSortId = this.timeSortControlVal;
      if (selectedTimeSortId) {
        const filteredSkills = this.filterSkillsByTimeSort(selectedTimeSortId);
        const selectedSkillId = this.skillControlVal;
        if (
          selectedSkillId &&
          !filteredSkills.find((s) => s.id === selectedSkillId)
        ) {
          this.skillFormControl.setValue(null);
        }
        this.filteredSkillsByTimeSort = filteredSkills;
        this.filterSkills();
      }
    }
    this.updateSkillValidator();
    this._timeSortFilter(this.timeSortControl.value ?? '');
  }

  onSkillChange(event: any) {
    this._skillFilter(this.skillControl.value ?? '');
  }

  onProjectChange(event: any) {
    if (event) {
      const selectedProjectId = this.projectControlVal;
      if (selectedProjectId) {
        const filteredTasks = this.filterTasksByProject(selectedProjectId);
        const selectedTaskId = this.taskControlVal;
        if (
          selectedTaskId &&
          !filteredTasks.find((t) => t.id === selectedTaskId)
        ) {
          this.taskFormControl.setValue(null);
        }
        this.filteredTasksByProject = filteredTasks;
        this.filterTasks();
      }
    }

    this._projectFilter(this.projectControl.value ?? '');
  }

  onDummyProjectChange(event: any) {
    this.showTasksDropdown = false;
    this._dummyProjectFilter(this.dummyProjectControl.value ?? '');
  }

  getSelectedUsers() {
    this.selectedUserIds = this.userControlVal;
    return this.data.users.filter(user => this.selectedUserIds.includes(user.id));
  }

  onUserChange(event: any) {
    this.selectedUserIds = this.userControlVal;
  }

  onCreateTaskChange(event: any): void {
    this.createNewTask = event.checked;
    this.selectedSubType = false;

    if (this.createNewTask) {
      this.typeControl?.setValue(null);
      this.registrationForm.controls['type'].setValidators([Validators.required]);
      this.registrationForm.controls['template'].setValidators([Validators.required]);
    }
    else {
      this.selectedTypeId = '';
      this.registrationForm.controls['type'].clearValidators();
      this.registrationForm.controls['template'].clearValidators();
    }
    this.registrationForm.controls['type'].updateValueAndValidity();
    this.registrationForm.controls['template'].updateValueAndValidity();
  }


  onTaskChange(event: any) {
    if (this.taskControlVal) {
      this.hasClear = true;
      this.createNewTask = false;
      this.selectedTypeId = "";
      this.registrationForm.get('createTask')?.setValue(this.createNewTask);
    }
    this._taskFilter(this.taskControl.value ?? '');
  }

  clearSelectedTask() {
    this.taskFormControl.reset();
    this.hasClear = false;
    this.filterTasks();
    this.showTasksDropdown = (this.tasks?.length ?? 0) > 0;
  }

  clearSelectedSkill() {
    this.skillFormControl.reset();
    this.filterSkills();
    this.showSkillsDropdown = (this.skills?.length ?? 0) > 1;
  }

  clearSelectedProject() {
    this.clearSelectedTask();
    this.projectFormControl.reset();
    this.filteredTasksByProject =
      this.tasks?.filter((t) => !t?.projectId)?.slice() ?? [];
    this.filterTasks();
  }

  clearSelectedDummyProject() {
    this.clearSelectedTask();
    this.dummyProjectFormControl.reset();
  }

  onActivityDropdownClick() {
    setTimeout(() => {
      this.timeSortDropdown?.nativeElement.focus();
    }, 0);
  }

  onTypeDropdownClick() {
    setTimeout(() => {
      this.typeDropdown?.nativeElement.focus();
    }, 0);
  }

  onTemplateDropdownClick() {
    setTimeout(() => {
      this.templateDropdown?.nativeElement.focus();
    }, 0);
  }

  onSkillDropdownClick() {
    setTimeout(() => {
      this.skillDropdown?.nativeElement.focus();
    }, 0);
  }

  onProjectDropdownClick() {
    setTimeout(() => {
      this.projectDropdown?.nativeElement.focus();
    }, 0);
  }
  onUserDropdownClick() {
    setTimeout(() => {
      this.userDropdown?.nativeElement.focus();
    }, 0);
  }
  onDummyProjectDropdownClick() {
    setTimeout(() => {
      this.dummyProjectDropdown?.nativeElement.focus();
    }, 0);
  }

  onTaskDropdownClick() {
    setTimeout(() => {
      this.taskDropdown?.nativeElement.focus();
    }, 0);
  }

  onCloseDialog() {
    this.detailsDialogRef.close();
  }

  onSave() {
    const errorOverlapMessage =
      this.getTranslation('timeRegistrationsOverlap') ??
      '(en) timeRegistrationsOverlap';
    this.isLoading = true;

    if (!this.registrationForm.valid) {
      if (this.registrationForm.get('title')?.hasError('maxlength')) {
        const errorShortenDescriptionMessage =
          this.getTranslation('shortenDescription') ??
          '(en) shortenDescription';
        this._snackBar
          .open(errorShortenDescriptionMessage, 'X', {
            panelClass: 'app-notification-error',
          })
          ._dismissAfter(SNACKBAR_DURATION_MS);
      } else {
        const errorInvalidFormMessage =
          this.getTranslation('invalidForm') ?? '(en) invalidForm';
        this._snackBar
          .open(errorInvalidFormMessage, 'X', {
            panelClass: 'app-notification-error',
          })
          ._dismissAfter(SNACKBAR_DURATION_MS);
      }
      this.isLoading = false;
      return;
    }
    const startTimeSplit = this.registrationForm
      .get('startTime')
      ?.value.split(':');
    const endTimeSplit = this.registrationForm.get('endTime')?.value.split(':');

    const startDateTime = (<DateTime>(
      this.registrationForm.get('startDate')?.value
    )).set({
      hour: parseInt(startTimeSplit[0]),
      minute: parseInt(startTimeSplit[1]),
    });
    // this.dateUtils.buildDateTime(
    //   this.registrationForm.get('startTime')?.value,
    //   this.registrationForm.get('startDate')?.value
    // );

    const endDateTime = (<DateTime>(
      this.registrationForm.get('endDate')?.value
    )).set({
      hour: parseInt(endTimeSplit[0]),
      minute: parseInt(endTimeSplit[1]),
    });
    // this.dateUtils.buildDateTime(
    //   this.registrationForm.get('endTime')?.value,
    //   this.registrationForm.get('endDate')?.value
    // );

    if (!(startDateTime && endDateTime && endDateTime > startDateTime)) {
      const errorStartDateBeforeEndDateMessage =
        this.getTranslation('endDateBeforeStartDate') ??
        '(en) endDateBeforeStartDate';
      this._snackBar
        .open(errorStartDateBeforeEndDateMessage, 'X', {
          panelClass: 'app-notification-error',
        })
        ._dismissAfter(SNACKBAR_DURATION_MS);
      this.isLoading = false;
      return;
    }

    if (!this.editMode) {
      const timeRegistrationsToAdd = this.userControlVal.map(id => new TimeRegistration({
        currentUserId: this.currentUserId,
        currentDataObject: this.selectedEntityObject ?? undefined,
        title: this.registrationForm.get('title')?.value,
        startDate: startDateTime, //startDateTime?.toString().split(' GMT')[0],
        endDate: endDateTime, //endDateTime?.toString().split(' GMT')[0],
        user: new GroupUser({
          user: new AADUser({
            id: id,
          }),
          group: undefined,
        }),
        userId: id,
        projectId: this.registrationForm.get('project')?.value
          ? this.projects?.find(
            (p) => p.id === this.registrationForm.get('project')?.value
          )?.id
          : null,
        dummyProject: this.registrationForm.get('dummyProject')?.value
          ? this.dummyProjects.find(
            (p) => p.id === this.registrationForm.get('dummyProject')?.value
          )
          : null,
        timeSortId: this.registrationForm.get('timeSort')?.value
          ? this.timeSorts?.find(
            (t) => t.id === this.registrationForm.get('timeSort')?.value
          )?.id
          : undefined,
        skillId: this.registrationForm.get('skill')?.value
          ? this.skills.find(
            (s) => s.id === this.registrationForm.get('skill')?.value
          )?.id
          : null,
        taskId: this.registrationForm.get('task')?.value
          ? this.tasks?.find(
            (t) => t.id === this.registrationForm.get('task')?.value
          )?.id
          : undefined,
        newTaskWanted: this.createNewTask,
        resourceUsers: this.getSelectedUsers().length > 0
          ? this.getSelectedUsers().map(u => new GroupUser({ user: u }))
          : [new GroupUser({
            user: new AADUser({
              id: id,
            }),
            group: undefined,
          })],
        typeId: this.createNewTask && this.selectedTypeId ? this.selectedTypeId : '',
        type: this.types.find(t => t.id == this.selectedTypeId)
      })
      )
      const overlaps = this.hasOverlapWithExisting(
        timeRegistrationsToAdd[0],
        this.timeRegistrations
      );

      if (overlaps) {
        this._snackBar.open(errorOverlapMessage, 'X', {
          panelClass: 'app-notification-error',
        });
        this.isLoading = false;
      } else {
        this.registrationForm.disable({ emitEvent: false });
        this.timeRegistrationFacade
          .addTimeRegistration$(timeRegistrationsToAdd)
          .pipe(
            catchError((error) => {
              console.error(error);
              this._snackBar
                .open(error, 'X', {
                  panelClass: 'app-notification-error',
                })
                ._dismissAfter(SNACKBAR_DURATION_MS);
              this.registrationForm.enable({ emitEvent: false });
              throw error;
            })
          )
          .subscribe((addedTimeRegistrations) => {
            if (addedTimeRegistrations) {
              this.isLoading = false;
              this.detailsDialogRef.close(addedTimeRegistrations);
              this.showSuccessSnackbar('Time Registration created');
              if (this.createNewTask) {
                const taskId = addedTimeRegistrations[0].taskId;
                if (taskId) {
                  this.apiService.getTaskById(taskId).subscribe((task) => {
                    this.tasks?.push(task);
                    this.loadTasks();
                    this.ishtarCommunicationsService.redirectToApp(
                      'Ishtar.Tasks',
                      'openTask',
                      taskId,
                      task
                    );
                  })
                }
              }
            }
          });
      }
    } else {
      const timeRegistrationToEdit = new TimeRegistration({
        currentUserId: this.currentUserId,
        id: this.selectedTimeRegistration.id,
        title: this.registrationForm.get('title')?.value,
        startDate: startDateTime, //startDateTime?.toString().split(' GMT')[0],
        endDate: endDateTime, //endDateTime?.toString().split(' GMT')[0],
        user: this.selectedTimeRegistration.user,
        projectId: this.registrationForm.get('project')?.value
          ? this.projects?.find(
            (p) => p.id === this.registrationForm.get('project')?.value
          )?.id
          : null,
        dummyProject: this.registrationForm.get('dummyProject')?.value
          ? this.dummyProjects.find(
            (p) => p.id === this.registrationForm.get('dummyProject')?.value
          )
          : null,
        timeSortId: this.registrationForm.get('timeSort')?.value
          ? this.timeSorts?.find(
            (t) => t.id === this.registrationForm.get('timeSort')?.value
          )?.id
          : undefined,
        skillId: this.registrationForm.get('skill')?.value
          ? this.skills.find(
            (s) => s.id === this.registrationForm.get('skill')?.value
          )?.id
          : null,
        taskId: this.registrationForm.get('task')?.value
          ? this.tasks?.find(
            (t) => t.id === this.registrationForm.get('task')?.value
          )?.id
          : null,
      });

      const overlaps = this.hasOverlapWithExisting(
        timeRegistrationToEdit,
        this.timeRegistrations
          .slice()
          .filter((t) => t.id !== timeRegistrationToEdit.id)
      );

      if (overlaps) {
        this._snackBar.open(errorOverlapMessage, 'X', {
          panelClass: 'app-notification-error',
        });
        this.isLoading = false;
      } else {
        this.registrationForm.disable({ emitEvent: false });
        this.timeRegistrationFacade
          .updateTimeRegistration$([timeRegistrationToEdit])
          .pipe(
            catchError((error) => {
              console.error(error);
              this._snackBar
                .open(error, 'X', {
                  panelClass: 'app-notification-error',
                })
                ._dismissAfter(SNACKBAR_DURATION_MS);
              this.registrationForm.enable({ emitEvent: false });
              throw error;
            })
          )
          .subscribe(() => {
            const timeRegistrationUpdatedMessage =
              this.getTranslation('timeRegistrationUpdated') ??
              '(en) timeRegistrationUpdated';
            this.isLoading = false;
            this.detailsDialogRef.close(timeRegistrationToEdit);
            this.showSuccessSnackbar(timeRegistrationUpdatedMessage);
          });
      }
    }
  }

  private loadTasks(): void {
    this.filteredTasksByProject = this.tasks?.filter((t) => !t?.projectId)?.slice() ?? [];
    const selectedProjectId = this.selectedTimeRegistration?.projectId;

    if (selectedProjectId) {
      const filteredTasks = this.filterTasksByProject(selectedProjectId);
      this.filteredTasksByProject = filteredTasks;
    }

    this.showTasksDropdown = (this.tasks?.length ?? 0) > 0;
    this.filterTasks();
  }

  private loadDummyProjects(): void {
    this.dummyProjectFacade.dummyProjects$.subscribe((dummyProjects) => {
      this.dummyProjects = dummyProjects;
    });
  }

  private loadTypes(): void {

    this.apiService.getTaskTypes()
      .subscribe((data) => {
        this.types = data;
      })

    const typeControlValueChanges$ =
      this.typeControl?.valueChanges.pipe(startWith('')) || of('');
    const typeFormControlValueChanges$ =
      this.typeControl?.valueChanges.pipe(startWith([])) || of([]);

    this.filteredTypes = combineLatest([
      typeControlValueChanges$,
      typeFormControlValueChanges$,
    ]).pipe(map(([value]) => this._typeFilter(value || '')));
  }

  private loadProjects(): void {
    const filteredProjects = this.projects?.filter((project) => {
      const hasNoPermission = project?.members?.length === 0 && project?.owners?.length === 0;
      const isMember = project.members?.find(
        (m) => m.id === this.msalService.userId
      );
      const isOwner = project.owners?.find(
        (o) => o.id === this.msalService.userId
      );

      return isMember || isOwner || hasNoPermission;
    });
    this.projects = filteredProjects;

    if (this.projects?.length ?? 0 > 0) {
      this.sortProjects();
      const projectControlValueChanges$ =
        this.projectControl?.valueChanges.pipe(startWith('')) || of('');
      const projectFormControlValueChanges$ =
        this.projectControl?.valueChanges.pipe(startWith([])) || of([]);

      this.filteredProjects = combineLatest([
        projectControlValueChanges$,
        projectFormControlValueChanges$,
      ]).pipe(map(([value]) => this._projectFilter(value || '')));
    } else if (this.dummyProjects?.length ?? 0 > 0) {
      this.sortDummyProjects();
      const dummyProjectControlValueChanges$ =
        this.dummyProjectControl?.valueChanges.pipe(startWith('')) || of('');
      const dummyProjectFormControlValueChanges$ =
        this.dummyProjectControl?.valueChanges.pipe(startWith([])) || of([]);

      this.filteredDummyProjects = combineLatest([
        dummyProjectControlValueChanges$,
        dummyProjectFormControlValueChanges$,
      ]).pipe(map(([value]) => this._dummyProjectFilter(value || '')));
    }
  }

  private loadTimeSorts(): void {
    const timeSortControlValueChanges$ =
      this.timeSortControl?.valueChanges.pipe(startWith('')) || of('');
    const timeSortFormControlValueChanges$ =
      this.timeSortControl?.valueChanges.pipe(startWith([])) || of([]);

    this.filteredTimeSorts = combineLatest([
      timeSortControlValueChanges$,
      timeSortFormControlValueChanges$,
    ]).pipe(map(([value]) => this._timeSortFilter(value || '')));
  }

  private hasOverlapWithExisting(
    otherTimeRegistration: TimeRegistration,
    existingTimeRegistrations: TimeRegistration[]
  ): boolean {
    return existingTimeRegistrations.some((existingRegistration) =>
      this.checkOverlap(existingRegistration, otherTimeRegistration)
    );
  }

  private checkOverlap(
    existingRegistration: TimeRegistration,
    otherTimeRegistration: TimeRegistration
  ): boolean {
    if (
      existingRegistration.startDate &&
      existingRegistration.endDate &&
      otherTimeRegistration.startDate &&
      otherTimeRegistration.endDate
    ) {
      const startDate1 = existingRegistration.startDate
        ? DateTime.fromISO(existingRegistration.startDate.toString(), { zone: 'utc' })
        : undefined; // this.dateUtils.parseDate(existingRegistration.startDate);
      const endDate1 = existingRegistration.endDate
        ? DateTime.fromISO(existingRegistration.endDate.toString(), { zone: 'utc' })
        : undefined; //this.dateUtils.parseDate(existingRegistration.endDate);
      const startDate2 = otherTimeRegistration.startDate; //new Date(otherTimeRegistration.startDate);
      const endDate2 = otherTimeRegistration.endDate; //new Date(otherTimeRegistration.endDate);

      if (startDate1 && endDate1) {
        return (
          (startDate2 > startDate1 && startDate2 < endDate1) ||
          (endDate2 > startDate1 && endDate2 < endDate1) ||
          (startDate1 > startDate2 && startDate1 < endDate2) ||
          (endDate1 > startDate2 && endDate1 < endDate2) ||
          (startDate2.valueOf() === startDate1.valueOf() &&
            endDate2.valueOf() === endDate1.valueOf())
        );
      }
    }

    return false;
  }

  private filterTasksByProject(projectId: string): Task[] {
    const filteredTasks =
      this.tasks?.slice()?.filter((task) => task.projectId === projectId) ??
      [];
    this.showTasksDropdown = (filteredTasks?.length ?? 0) > 0;
    return filteredTasks;
  }

  private filterSkillsByTimeSort(timeSortId: string): Skill[] {
    const timeSort = this.timeSorts?.find((t) => t.id === timeSortId);
    const filteredSkills =
      this.skills
        ?.slice()
        ?.filter((skill) => timeSort?.skills?.find((s) => s.id === skill.id)) ??
      [];
    this.showSkillsDropdown = (filteredSkills?.length ?? 0) > 0;
    return filteredSkills;
  }

  private handleStartDateTimeChanged(): void {
    this.valueChangesSubscription?.add(
      this.registrationForm
        .get('startDate')
        ?.valueChanges.subscribe((startDate) => {
          this.registrationForm.get('endDate')?.setValue(startDate);
        })
    );

    this.subscribeToStartTimeChanges();
  }

  private subscribeToStartTimeChanges(): void {
    this.valueChangesSubscription?.add(
      this.registrationForm
        .get('startTime')
        ?.valueChanges.subscribe((startTime) => {
          if (startTime) {
            const startDate = this.registrationForm.get('startDate')?.value;
            //const isLuxonDate = startDate?.isLuxonDateTime;

            // const startYear = isLuxonDate
            //   ? startDate?.c?.year
            //   : startDate.getFullYear();
            // const startMonth = isLuxonDate
            //   ? startDate?.c?.month - 1
            //   : startDate.getMonth();
            // const startDay = isLuxonDate
            //   ? startDate?.c?.day
            //   : startDate.getDate();

            const parseTimeValue = (startTime: string) => {
              const [timePart, amPmPart] = startTime.split(' ');
              const timeParts = timePart.split(':').map(Number);
              let hours = timeParts[0];
              const minutes = timeParts[1];

              const isPM = amPmPart === 'PM';

              if (isPM && hours !== 12) {
                hours += 12;
              }

              return { hours, minutes };
            };
            const start = parseTimeValue(startTime);
            const startHrs = start.hours;
            const startMins = start.minutes;

            const end = parseTimeValue(
              this.registrationForm.get('endTime')?.value
            );

            const currentEndHrs = end.hours;
            const currentEndMins = end.minutes;

            let startDateTime = startDate.set({
              hour: startHrs,
              minute: startMins,
            });
            // new Date(
            //   startYear,
            //   startMonth,
            //   startDay,
            //   startHrs,
            //   startMins
            // );
            const endDateTime = startDate.set({
              hour: startDateTime.hour + this.defaultTimeSlot,
              minute: end.minutes,
            });
            // new Date(startDateTime);
            // endDateTime.setHours(
            //   startDateTime.getHours() + this.defaultTimeSlot
            // );

            const endTimeString =
              ('0' + endDateTime.getHours()).slice(-2) +
              ':' +
              ('0' + endDateTime.getMinutes()).slice(-2);

            const endhours = endDateTime.hour;
            const endminutes = endDateTime.minute;
            if (
              endhours < startHrs ||
              (endhours === startHrs && endminutes < startMins)
            ) {
              startDateTime = startDateTime.plus({
                hours: this.defaultTimeSlot,
              });
              // startDateTime.setDate(
              //   startDateTime.getDate() + this.defaultTimeSlot
              // );
              this.registrationForm.get('endDate')?.setValue(startDateTime);
            }
            if (
              currentEndHrs < startHrs ||
              (currentEndHrs === startHrs && currentEndMins < startMins)
            )
              this.registrationForm.get('endTime')?.setValue(endTimeString);
          }
        })
    );
  }

  private sortProjects(): void {
    if (this.projects) {
      const mutableProjects = [...this.projects];

      mutableProjects.sort((a, b) => {
        if (a.projectId && b.projectId) {
          const projectIdA = parseInt(a.projectId, 10);
          const projectIdB = parseInt(b.projectId, 10);
          return projectIdB - projectIdA;
        }
        return 0;
      });

      this.projects = mutableProjects;
    }
  }

  private sortDummyProjects(): void {
    if (this.dummyProjects) {
      const mutableProjects = [...this.dummyProjects];

      mutableProjects.sort((a, b) => {
        const projectIdA = a.projectNumber;
        const projectIdB = b.projectNumber;
        return projectIdB - projectIdA;
      });

      this.dummyProjects = mutableProjects;
    }
  }

  private filterTasks(): void {
    const taskControlValueChanges$ =
      this.taskControl?.valueChanges.pipe(startWith('')) || of('');
    const taskFormControlValueChanges$ =
      this.taskControl?.valueChanges.pipe(startWith([])) || of([]);

    this.filteredTasks = combineLatest([
      taskControlValueChanges$,
      taskFormControlValueChanges$,
    ]).pipe(map(([value]) => this._taskFilter(value || '')));
  }

  private filterSkills(): void {
    const skillControlValueChanges$ =
      this.skillControl?.valueChanges.pipe(startWith('')) || of('');
    const skillFormControlValueChanges$ =
      this.skillControl?.valueChanges.pipe(startWith([])) || of([]);

    this.filteredSkills = combineLatest([
      skillControlValueChanges$,
      skillFormControlValueChanges$,
    ]).pipe(map(([value]) => this._skillFilter(value || '')));
  }

  private _timeSortFilter(value: string): TimeSort[] | undefined {
    const filterValue = value.toLowerCase();

    return this.timeSorts
      ?.filter(
        (t) =>
          t.sort?.toLowerCase().includes(filterValue) &&
          !this.timeSortControlVal.includes(t.id ?? 'x')
      )
      .sort((a, b) => (a.sort ?? '').localeCompare(b.sort ?? ''));
  }

  private _typeFilter(value: string): TaskType[] | undefined {
    const filterValue = value.toLowerCase();

    return this.types
      ?.filter(
        (t) =>
          t.type?.toLowerCase().includes(filterValue) &&
          !this.typeControlVal.includes(t.id ?? 'x')
      )
      .sort((a, b) => (a.type ?? '').localeCompare(b.type ?? ''));
  }

  private _templateFilter(value: string): EntityObject<IshtarTask>[] | undefined {
    const filterValue = value.toLowerCase();

    return this.selectedEntityObjects
      ?.filter(
        (t) =>
          t.form?.name?.toLowerCase().includes(filterValue) &&
          !this.templateControlVal.includes(t.id ?? 'x')
      )
      .sort((a, b) => (a.form?.name ?? '').localeCompare(b.form?.name ?? ''));
  }

  private _skillFilter(value: string): Skill[] | undefined {
    const filterValue = value.toLowerCase();

    return this.filteredSkillsByTimeSort?.filter(
      (s) =>
        s.title?.toLowerCase().includes(filterValue) &&
        !this.skillControlVal?.includes(s.id ?? 'x')
    );
  }

  private _projectFilter(value: string): Project[] | undefined {
    const filterValue = value.toLowerCase();
    return this.projects?.filter(
      (p) =>
        (p.projectName?.toLowerCase().includes(filterValue) ||
          p.projectId?.toLowerCase().includes(filterValue) ||
          p.client?.clientName?.toLowerCase().includes(filterValue)) &&
        !this.projectControlVal?.includes(p.id ?? 'x')
    );
  }

  private _dummyProjectFilter(value: string): DummyProject[] | undefined {
    const filterValue = value.toLowerCase();
    return this.dummyProjects?.filter(
      (p) =>
        (p.title?.toLowerCase().includes(filterValue) ||
          p.projectNumber?.toString().includes(filterValue)) &&
        !this.dummyProjectControlVal?.includes(p.id ?? 'x')
    );
  }

  private _taskFilter(value: string): Task[] | undefined {
    const filterValue = value.toLowerCase();
    return this.filteredTasksByProject?.filter(
      (t) =>
        t.title?.toLowerCase().includes(filterValue) &&
        !this.taskControlVal?.includes(t.id ?? 'x')
    );
  }

  private async showSuccessSnackbar(message: string) {
    await this._snackBar
      .open(message, 'Ok', {
        panelClass: 'app-notification-success',
      })
      ._dismissAfter(SNACKBAR_DURATION_MS);
  }

  private initForm(): void {
    if (!this.selectedTimeRegistration) {
      this.newTimeRegistration = true;
    }

    this.registrationForm = new FormGroup({
      title: new FormControl(
        {
          value: this.selectedTimeRegistration
            ? this.selectedTimeRegistration.title
            : '',
          disabled: false,
        },
        [Validators.required, Validators.maxLength(700)]
      ),
      startDate: new FormControl(
        {
          value: this.startDate,
          disabled: false,
        },
        Validators.required
      ),
      createTask: new FormControl(
        {
          value: this.newTimeRegistration ? false : this.selectedTimeRegistration.newTaskWanted
            ? this.selectedTimeRegistration.newTaskWanted
            : false,
          disabled: false,
        },
      ),
      startTime: new FormControl(
        { value: this.getSafeStartTime(), disabled: false },
        Validators.required
      ),
      endDate: new FormControl(
        {
          value: this.endDate,
          disabled: false,
        },
        Validators.required
      ),
      endTime: new FormControl(
        { value: this.getSafeEndTime(), disabled: false },
        Validators.required
      ),
      timeSort: new FormControl(
        {
          value: this.selectedTimeRegistration?.timeSortId
            ? this.selectedTimeRegistration.timeSortId
            : '',
          disabled: false,
        },
        Validators.required
      ),
      type: new FormControl(
        {
          value: this.newTimeRegistration ? '' : this.selectedTimeRegistration.typeId
            ? this.selectedTimeRegistration.typeId
            : '',
          disabled: false,
        },
      ),
      template: new FormControl(
        {
          value: '',
          disabled: false,
        },
      ),
      skill: new FormControl({
        value: this.selectedTimeRegistration?.skillId
          ? this.selectedTimeRegistration.skillId
          : '',
        disabled: false,
      }),
      project: new FormControl({
        value: this.selectedTimeRegistration?.projectId
          ? this.selectedTimeRegistration.projectId
          : '',
        disabled: false,
      }),
      dummyProject: new FormControl({
        value: this.selectedTimeRegistration?.dummyProject?.id
          ? this.selectedTimeRegistration.dummyProject?.id
          : '',
        disabled: false,
      }),
      task: new FormControl({
        value: this.selectedTimeRegistration?.taskId
          ? this.selectedTimeRegistration.taskId
          : '',
        disabled: false,
      }),
      users: new FormControl({
        value: this.data.selectedUserIds
          ? this.data.selectedUserIds
          : [],
        disabled: false,
      },
        Validators.required),
    });
    this.updateSkillValidator();
  }
  getSafeStartTime() {
    const startTime =
      this.startDate.hour.toString().padStart(2, '0') +
      ':' +
      this.startDate.minute.toString().padStart(2, '0');
    if (this.editMode || this.copyMode || this.timeRegistrations.length === 0) {
      return startTime;
    }
    const date = this.startDate.toFormat('dd/MM/yyyy');
    // const date = [
    //   this.startDate.getDate().toString().padStart(2, '0'),
    //   (this.startDate.getMonth() + 1).toString().padStart(2, '0'),
    //   this.startDate.getFullYear(),
    // ].join('/');
    for (const timeRegistration of this.timeRegistrations) {
      if (!timeRegistration.startDate || !timeRegistration.endDate) continue;
      const startDate = DateTime.fromISO(timeRegistration.startDate.toString(), { zone: 'utc' });
      const endDate = DateTime.fromISO(timeRegistration.endDate.toString(), { zone: 'utc' });
      const timeRegStartDate = startDate.toFormat('dd/MM/yyyy');
      // timeRegistration.startDate
      //   ?.toString()
      //   .split(' ')[0];
      const timeRegEndTime = endDate.hour.toString().padStart(2, '0') + ':' + endDate.minute.toString().padStart(2, '0'); //timeRegistration.endDate?.toString().split(' ')[1];
      const timeRegStartTime = startDate.hour.toString().padStart(2, '0') + ':' + startDate.minute.toString().padStart(2, '0');
      // timeRegistration.startDate
      //   ?.toString()
      //   .split(' ')[1];
      if (
        timeRegStartDate === date &&
        startTime < timeRegEndTime &&
        startTime >= timeRegStartTime
      ) {
        return endDate.toFormat('HH:mm:ss');
        // return timeRegistration.endDate.toString().split(' ')[1];
      }
    }
    return startTime;
  }
  getSafeEndTime() {
    const endTime =
      this.endDate.hour.toString().padStart(2, '0') +
      ':' +
      this.endDate.minute.toString().padStart(2, '0');
    if (this.editMode || this.copyMode || this.timeRegistrations.length === 0) {
      return endTime;
    }
    const date = this.endDate.toFormat('dd/MM/yyyy');
    // const date = [
    //   // this.endDate.getDate().toString().padStart(2, '0'),
    //   // (this.endDate.getMonth() + 1).toString().padStart(2, '0'),
    //   // this.endDate.getFullYear(),
    // ].join('/');
    for (const timeRegistration of this.timeRegistrations) {
      if (!timeRegistration.startDate || !timeRegistration.endDate) continue;
      const startDate = DateTime.fromISO(timeRegistration.startDate.toString(), { zone: 'utc' });
      const endDate = DateTime.fromISO(timeRegistration.endDate.toString(), { zone: 'utc' });
      const timeRegEndDate = endDate.toFormat('dd/MM/yyyy'); //timeRegistration.endDate?.toString().split(' ')[0];
      const timeRegStartTime = startDate.hour.toString().padStart(2, '0') + ':' + startDate.minute.toString().padStart(2, '0');
      // timeRegistration.startDate
      //   ?.toString()
      //   .split(' ')[1];
      const timeRegEndTime = endDate.hour.toString().padStart(2, '0') + ':' + endDate.minute.toString().padStart(2, '0');
      //timeRegistration.endDate?.toString().split(' ')[1];
      if (
        timeRegEndDate === date &&
        endTime > timeRegStartTime &&
        endTime <= timeRegEndTime
      ) {
        return startDate.toFormat('HH:mm:ss');
        // return timeRegistration.startDate.toString().split(' ')[1];
      }
    }
    return endTime;
  }

  updateSkillValidator(): void {
    this.registrationForm
      .get('skill')
      ?.setValidators(this.showSkillsDropdown ? Validators.required : null);
    this.registrationForm.get('skill')?.updateValueAndValidity();
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
