import { BaseEntity, EntityObject, GroupUser } from 'processdelight-angular-components';
import { DateTime } from 'luxon';
import { DummyProject } from './dummy-project.model';
import { IshtarTask } from './ishtar-task.model';
import { TaskType } from './task-type.model';

export class TimeRegistration extends BaseEntity {
  currentUserId?: string;
  title?: string;
  startDate?: DateTime;
  endDate?: DateTime;
  user?: GroupUser;
  projectId?: string | null = null;
  dummyProject?: DummyProject | null = null;
  timeSortId!: string;
  taskId?: string | null = null;
  typeId?: string | null = null;
  type?: TaskType | null = null;
  skillId?: string | null = null;
  newTaskWanted?: boolean = false;
  userId?: string | null = null;
  resourceUsers: GroupUser[] | undefined = [];
  currentDataObject?: EntityObject<IshtarTask>;

  constructor(obj: Partial<TimeRegistration>) {
    super(obj);
    Object.assign(this, obj);
  }
}
