import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError, tap } from 'rxjs';
import { DateStartEnd, EntityObject, Filter, GroupUser } from 'processdelight-angular-components';
import { FunctionsService } from './functions.service';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';
import { Task } from '../domain/models/task';
import { Language } from '../domain/models/language.model';
import { Project } from '../domain/models/project';
import { camelcaseKeys } from '../helper/object.functions';
import { PublicHolidayModel } from '../domain/models/public-holiday.model';
import { UserSkill } from '../domain/models/user-skill';
import { TimeSort } from '../domain/models/time-sort';
import { Skill } from '../domain/models/skill.model';
import { DummyProject } from '../domain/models/dummy-project.model';
import { TeamCalendarItem } from '../domain/models/team-calendar-item';
import { TimeRegistration } from '../domain/models/time-registration.model';
import { TimeSortType } from '../domain/enums/time-sort-type';
import { TaskType } from '../domain/models/task-type.model';
import { IshtarTask } from '../domain/models/ishtar-task.model';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient,
    private functionsService: FunctionsService
  ) {}

  getFormTemplates() {
    return this.httpClient
      .get<EntityObject<IshtarTask>[]>(`${this.apiBase}/dc/formTemplates`)
      .pipe(
        map((forms) =>
          forms.map((f) => new EntityObject<IshtarTask>(this.camelcaseKeys(f)))
        )
      );
  }

  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }

  getLicense(tenantId: string) {
    return this.httpClient.post<UserLicenseInfo>(
      `${this.apiBase}/session/register?tenantId=${tenantId}`,
      {}
    );
  }

  public getTaskTypes(): Observable<TaskType[]> {
    const url = `${this.apiBase}/type/task`;
    return this.httpClient.get<TaskType[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.apiBase}/session/keepalive`, {});
  }

  /*
  getAppInfo(app: string) {
    return this.httpClient
      .get<AppInfo>(`${this.apiBase}/organization/app/${app}`)
      .pipe(map((info) => new AppInfo(camelcaseKeys(info))));
  }
  */

  public getProjects() {
    return this.httpClient.get<Project[]>(`${this.apiBase}/project`)
      .pipe(map((projects) => projects.map((p) => new Project(camelcaseKeys(p)))));
  }

  getTasks() {
    return this.httpClient.get<Task[]>(`${this.apiBase}/task`)
      .pipe(map((tasks) => tasks.map((t) => new Task(camelcaseKeys(t)))));
  }

  public getTaskById(id: string): Observable<Task> {
    return this.httpClient.get<Task>(`${this.apiBase}/task/${id}`)
      .pipe(
        map((task) => new Task(task)),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getLanguages() {
    return this.httpClient.get<Language[]>(`${this.apiBase}/user/languages`)
      .pipe(map((ls) => ls.map((l) => new Language(camelcaseKeys(l)))));
  }

  getTranslations(lang: string) {
    return this.httpClient.get<any>(`${this.apiBase}/user/translations/${lang}`);
  }

  getPublicHolidays(year: number) {
    return this.httpClient.get<PublicHolidayModel[]>(`${this.apiBase}/public-holiday/${year}`)
      .pipe(map((publicHolidays) => publicHolidays.map((p) => new PublicHolidayModel(camelcaseKeys(p)))));
  }
  getTimeSorts() {
    return this.httpClient.get<TimeSort[]>(`${this.apiBase}/time-sort?filter(type=${TimeSortType.Time})`)
      .pipe(map((timeSorts) => timeSorts.map((t) => new TimeSort(camelcaseKeys(t)))));
  }

  addTimeSort(timeSorts: TimeSort[]) {
    return this.httpClient.post<TimeSort[]>(`${this.apiBase}/time-sort`, timeSorts)
      .pipe(map((timeSorts) => timeSorts.map((s) => new TimeSort(camelcaseKeys(s)))));
  }

  updateTimeSort(timeSorts: TimeSort[]) {
    return this.httpClient.patch<TimeSort[]>(`${this.apiBase}/time-sort`, timeSorts)
      .pipe(map((timeSorts) => timeSorts.map((t) => new TimeSort(camelcaseKeys(t)))));
  }

  removeTimeSorts(timeSortIds: string[]) {
    return this.httpClient.delete<boolean>(`${this.apiBase}/time-sort`, { body: timeSortIds });
  }
  getUserSkills(filters: Filter[]) {
    let url = `${this.apiBase}/ishtartasks/user-skill`;

    if (filters.length > 0)
      url += `?filter=${this.filterQuery(filters,' or ')}`;

    return this.httpClient.get<UserSkill[]>(url)
      .pipe(map((userSkills) => userSkills.map((u) => new UserSkill(camelcaseKeys(u)))));
  }

  getSkills() {
    return this.httpClient.get<Skill[]>(`${this.apiBase}/skill`)
      .pipe(map((skills) => skills.map((s) => new Skill(camelcaseKeys(s)))));
  }

  getDummyProjects() {
    return this.httpClient.get<DummyProject[]>(`${this.apiBase}/dummy-project`)
      .pipe(map((dummyProjects) => dummyProjects.map((p) => new DummyProject(camelcaseKeys(p)))));
  }

  addDummyProject(dummyProjects: DummyProject[]) {
    return this.httpClient
      .post<DummyProject[]>(`${this.apiBase}/dummy-project`, dummyProjects)
      .pipe(map((dummyProjects) => dummyProjects.map((d) => new DummyProject(camelcaseKeys(d)))));
  }

  updateDummyProject(dummyProjects: DummyProject[]) {
    return this.httpClient.patch<DummyProject[]>(`${this.apiBase}/dummy-project`, dummyProjects)
      .pipe(map((dummyProjects) => dummyProjects.map((d) => new DummyProject(camelcaseKeys(d)))));
  }

  removeDummyProjects(dummyProjectIds: string[]) {
    return this.httpClient.delete<boolean>(`${this.apiBase}/dummy-project`, { body: dummyProjectIds });
  }

  getIshtarOoOAbsences(filters: Filter[]) {
    let url = `${this.apiBase}/team-calendar`;

    if (filters.length > 0) {
      url += `?${this.filterOoOAbsencesQuery(filters)}`;
    }

    return this.httpClient.get<TeamCalendarItem[]>(url)
      .pipe(map((calendarItems) => calendarItems.map((c) => new TeamCalendarItem(camelcaseKeys(c)))));
  }

  getTimeRegistrations(filters: Filter[], loadTimeInterval: DateStartEnd) {
    let url = `${this.apiBase}/time-registration`;
    
    const filterQuery = this.filterQuery(filters, ' or ');
    const intervalQuery = this.getIntervalToFilter(loadTimeInterval);
      url+= `?filter=${filters.length>0 ? `(${filterQuery}) ${intervalQuery ? `and (${intervalQuery })` : ''}`:`${intervalQuery }`}`

    return this.httpClient.get<TimeRegistration[]>(url)
        .pipe(map((timeRegistrations) => timeRegistrations.map((t) => new TimeRegistration(camelcaseKeys(t)))));
}
  addTimeRegistration(timeRegistrations: TimeRegistration[]) {
    return this.httpClient.post<TimeRegistration[]>(`${this.apiBase}/time-registration`, timeRegistrations)
      .pipe(map((timeRegistrations) => timeRegistrations.map((t) => new TimeRegistration(camelcaseKeys(t)))));
  }

  updateTimeRegistration(timeRegistrations: TimeRegistration[]) {
    return this.httpClient.patch<TimeRegistration[]>(`${this.apiBase}/time-registration`, timeRegistrations)
      .pipe(map((timeRegistrations) => timeRegistrations.map((t) => new TimeRegistration(camelcaseKeys(t)))));
  }

  removeTimeRegistrations(timeRegistrationIds: string[]) {
    return this.httpClient.delete<string[]>(`${this.apiBase}/time-registration`, { body: timeRegistrationIds });
  }

  getGroups() {
    return this.httpClient.get<GroupUser[]>(`${this.apiBase}/organization/groups`)
      .pipe(map((group) => group.map((g) => new GroupUser(camelcaseKeys(g)))));
  }

  private filterOoOAbsencesQuery(filters: Filter[]) {
    if (filters.length > 0) {
      return (
        'filter=' +
        filters
          .map((filter) => {
            if (filter.columnName === 'VacationType/Name') {
              return `(${filter.columnName} ne '${filter.value}')`;
            } else {
              return `('${filter.value}' in ${filter.columnName})`;
            }
          })
          .join(' and ')
      );
    } else {
      return '';
    }
  }

  private filterQuery(filters: Filter[], joinValue: string) {
    if (filters.length > 0) {
        return filters
            .map((filter) => `(${filter.columnName} ${filter.operator ?? 'eq'} ${filter.value})`)
            .join(joinValue ?? ' and ');
    } else {
        return '';
    }
}

private getIntervalToFilter(loadTimeInterval: DateStartEnd) {
  let filter=''
    if (loadTimeInterval.start) {
      const startDate = loadTimeInterval.start.toISO({ suppressMilliseconds: true, includeOffset: false });
      filter =filter+`(startDate ge ${startDate})`;
    }
    if (loadTimeInterval.end) {
      const endDate = loadTimeInterval.end.toISO({ suppressMilliseconds: true, includeOffset: false });

      filter =filter+(filter.length>0?' and ':'')+`(endDate le ${endDate})`;
    }
    return filter;
}
}
